body {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--body-color);

  @include media('>=desktop') {
    font-size: var(--body-font-size-desktop);
  }
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    font-family: var(--heading-font-family);
    font-size: var(--heading-#{$i}-font-size);
    line-height: var(--heading-line-height);
    margin: 0 0 23px;

    @include media('>=desktop') {
      font-size: var(--heading-#{$i}-font-size-desktop);
    }
  }
}

p {
  margin: 0 0 23px;
}

a {
  color: var(--body-color);
}
