.materials {
  margin: 68px 0;

  @include media(">=desktop") {
    margin: 127px 0;
  }

  .row {
    margin: 0 -86px -50px -86px;
  }

  .col {
    width: 100%;
    margin-bottom: 50px;
    padding: 0 86px;

    @include media(">=desktop") {
      width: 50%;
    }
  }

  h2 {
    letter-spacing: -1.84px;
    margin-bottom: 10px;

    @include media(">=desktop") {
      letter-spacing: -3.22px;
      margin-bottom: 15px;
    }

    &.h3 {
      letter-spacing: -1.2px;
      margin-bottom: 25px;

      @include media(">=desktop") {
        letter-spacing: -1.38px;
        margin-bottom: 40px;
      }
    }
  }

  h3 {
    &.h4 {
      letter-spacing: -0.92px;
      margin-bottom: 19px;

      @include media(">=desktop") {
        letter-spacing: -1.2px;
        margin-bottom: 35px;
      }
    }
  }

  p {
    letter-spacing: -0.74px;
    margin-bottom: 25px;

    @include media(">=desktop") {
      letter-spacing: -1.01px;
      margin-bottom: 30px;
    }
  }

  .buttons {
    @extend %list-reset;
    margin-bottom: -15px;

    @include media(">=desktop") {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px -15px;
    }

    li {
      margin-bottom: 15px;

      @include media(">=desktop") {
        padding: 0 15px;
      }
    }
  }

  .files {
    @extend %list-reset;
    margin-bottom: -19px;

    @include media(">=desktop") {
      margin-bottom: -30px;
    }

    li {
      margin-bottom: 19px;

      @include media(">=desktop") {
        margin-bottom: 30px;
      }

      a {
        color: var(--body-color);
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;

        &:hover {
          .file-hold {
            h3 {
              &.h5 {
                text-decoration: none;
              }
            }
          }
        }

        .icon-hold {
          width: 22px;

          i {
            font-size: rem(29px);
          }
        }

        .file-hold {
          width: calc(100% - 22px);
          padding-left: 14px;

          h3 {
            &.h5 {
              letter-spacing: -0.83px;
              text-decoration: underline;
              margin-bottom: 10px;

              @include media(">=desktop") {
                letter-spacing: -0.92px;
              }
            }
          }

          p {
            letter-spacing: 0;
            margin-bottom: 0;

            @include media(">=desktop") {
              font-size: rem(20px);
            }
          }
        }
      }
    }
  }
}
