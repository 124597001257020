.banner {
  text-align: center;
  margin: 68px 0;

  @include media(">=desktop") {
    margin: 200px 0;
  }

  h1 {
    font-weight: 900;
    letter-spacing: -2.2px;
    line-height: 1;
    margin-bottom: 23px;

    @include media(">=desktop") {
      letter-spacing: -3.96px;
      margin-bottom: 48px;
    }
  }

  p {
    font-size: rem(20px);
    max-width: 1038px;
    margin: 0 auto 23px auto;

    @include media(">=desktop") {
      font-size: rem(30px);
      margin-bottom: 48px;
    }
  }

  .btn {
    i {
      margin-left: 19px;
    }
  }
}
