$html-font-size: 16px;

:root {
  --body-font-family: "Abel", sans-serif;
  --body-font-size: #{rem(16px)};
  --body-font-size-desktop: #{rem(22px)};
  --body-line-height: 1.27;
  --body-color: #232323;
  --wrapper-mask-color: #fddac8;
  --wrapper-mask-2-color: #eeeeee;
  --heading-font-family: "Playfair Display", serif;
  --heading-line-height: 1.33;
  --heading-1-font-size: #{rem(50px)};
  --heading-1-font-size-desktop: #{rem(90px)};
  --heading-2-font-size: #{rem(40px)};
  --heading-2-font-size-desktop: #{rem(70px)};
  --heading-3-font-size: #{rem(26px)};
  --heading-3-font-size-desktop: #{rem(30px)};
  --heading-4-font-size: #{rem(20px)};
  --heading-4-font-size-desktop: #{rem(26px)};
  --heading-5-font-size: #{rem(18px)};
  --heading-5-font-size-desktop: #{rem(20px)};
  --heading-6-font-size: #{rem(16px)};
  --heading-6-font-size-desktop: #{rem(18px)};
  --container-width: #{rem(1310px)};
  --gutter-width: #{rem(30px)};
  --header-nav-link-font-family: var(--heading-font-family);
  --header-nav-link-color: #000000;
  --btn-font-family: var(--heading-font-family);
  --btn-font-size: #{rem(20px)};
  --btn-line-height: 1.35;
  --btn-ghost-front-color: #232323;
  --btn-ghost-back-color: #ffffff;
  --btn-light-front-color: #ffffff;
  --btn-light-back-color: #232323;
  --btn-dark-front-color: #FDDAC8;
  --btn-dark-back-color: #232323;
  --materials-rtl-dark-front-color: #ffffff;
  --materials-rtl-dark-back-color: #232323;
  --materials-rtl-light-front-color: #000000;
  --materials-rtl-light-back-color: #eeeeee;
  --cta-back-color: #FDDAC8;
  --footer-front-color: #ffffff;
  --footer-back-color: #232323;
  --footer-nav-secondary-separator-color: #000000;
}
