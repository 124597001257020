.cta {
  text-align: center;
  margin: 68px 0;
  padding: 68px 0;
  background: var(--cta-back-color);

  @include media(">=desktop") {
    margin: 127px 0;
    padding: 127px 0;
  }

  h2 {
    letter-spacing: -1.84px;
    display: inline-block;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 2px solid;

    @include media(">=desktop") {
      letter-spacing: -3.22px;
      margin-bottom: 37px;
      padding-bottom: 37px;
    }
  }

  p {
    letter-spacing: -0.74px;
    max-width: 620px;
    margin: 0 auto 25px auto;

    @include media(">=desktop") {
      font-size: rem(22px);
      letter-spacing: -1.01px;
      margin-bottom: 30px;
    }
  }
}
