.logos {
  margin: 68px 0;

  @include media(">=desktop") {
    margin: 127px 0;
  }

  .row {
    margin: 0 -40px -40px;

    @include media(">=desktop") {
      margin: 0 -80px -80px;
    }
  }

  .col {
    text-align: center;
    width: 50%;
    padding: 0 40px;
    margin-bottom: 40px;

    @include media(">=desktop") {
      width: 33.3333%;
      padding: 0 80px;
      margin-bottom: 80px;
    }
  }
}
