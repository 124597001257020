.header {
  padding-top: 20px;

  @include media(">=desktop") {
    padding-top: 60px;
  }

  .row {
    @include media(">=desktop") {
      align-items: center;
    }
  }

  .logo-hold {
    width: 204px;
    margin: 0 auto 36px auto;

    @include media(">=desktop") {
      width: 270px;
      margin: 0;
    }
  }

  .nav-hold {
    width: 100%;

    @include media(">=desktop") {
      width: auto;
      margin-left: auto;
    }
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
  }

  .nav-main,
  .nav-secondary {
    @extend %list-reset;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include media(">=desktop") {
      align-items: center;
      width: auto;
    }

    li {
      &.active {
        a {
          font-weight: 700;
          text-decoration: underline;
        }
      }

      a {
        font-family: var(--header-nav-link-font-family);
        font-size: rem(18px);
        color: var(--header-nav-link-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .nav-main {
    order: 2;
    margin-bottom: -15px;

    @include media(">=desktop") {
      order: 1;
      margin-right: -35px;
      margin-bottom: 0;
    }

    li {
      text-align: center;
      width: 100%;
      margin-bottom: 15px;

      @include media(">=desktop") {
        width: auto;
        margin-right: 35px;
        margin-bottom: 0;
      }

      a {
        font-weight: 700;
        letter-spacing: -0.92px;

        @include media(">=desktop") {
          font-size: rem(22px);
        }

        i {
          margin-left: 8px;
        }
      }
    }
  }

  .nav-secondary {
    order: 1;
    justify-content: center;
    margin-bottom: 35px;

    @include media(">=desktop") {
      order: 2;
      justify-content: flex-start;
      margin-bottom: 0;
      margin-left: 80px;
    }

    @include media(">=widescreen") {
      margin-left: 120px;
    }

    li {
      & + li {
        &:before {
          content: "|";
          margin: 0 10px;
        }
      }

      a {
        letter-spacing: -0.83px;
      }
    }
  }
}
