html {
  font-size: $html-font-size;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

img {
  max-width: 100%;
}
