%list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

%button-reset {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
}
