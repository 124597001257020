.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 270px;
    height: 249px;
    background-color: var(--wrapper-mask-color);
    -webkit-mask-image: url(../images/mask.svg);
    mask-image: url(../images/mask.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    mask-size: 100%;

    @include media(">=desktop") {
      width: 589px;
      height: 542px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 446px;
    right: 0;
    z-index: -1;
    width: 187px;
    height: 366px;
    background-color: var(--wrapper-mask-2-color);
    -webkit-mask-image: url(../images/mask-2.svg);
    mask-image: url(../images/mask-2.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    mask-size: 100%;

    @include media(">=desktop") {
      width: 409px;
      height: 797px;
    }
  }
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 calc(var(--gutter-width) * 0.5);
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gutter-width) * -0.5);
}

.col {
  padding: 0 calc(var(--gutter-width) * 0.5);
}

.btn {
  @extend %button-reset;
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: 700;
  letter-spacing: -0.92px;
  line-height: var(--btn-line-height);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 16px 25px;
  border: 2px solid transparent;
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out;

  &[dir="rtl"] {
    i {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  &.btn-ghost {
    color: var(--btn-ghost-front-color);
    border-color: var(--btn-ghost-front-color);

    &:hover {
      color: var(--btn-ghost-back-color);
      background-color: var(--btn-ghost-front-color);
    }
  }

  &.btn-light {
    color: var(--btn-light-front-color);
    border-color: var(--btn-light-front-color);

    &:hover {
      color: var(--btn-light-back-color);
      background-color: var(--btn-light-front-color);
    }
  }

  &.btn-dark {
    color: var(--btn-dark-front-color);
    background-color: var(--btn-dark-back-color);
    border-color: var(--btn-dark-back-color);

    &:hover {
      color: var(--btn-dark-back-color);
      background-color: var(--btn-dark-front-color);
    }
  }

  i {
    margin-left: 8px;
  }
}
