.about-site {
  margin: 68px 0;

  @include media(">=desktop") {
    margin: 127px 0;
  }

  .row {
    margin: 0 -86px -30px;
  }

  .col {
    width: 100%;
    margin-bottom: 30px;
    padding: 0 86px;

    @include media(">=desktop") {
      width: 50%;
    }
  }

  h2 {
    font-size: rem(30px);
    letter-spacing: -2.3px;
    margin-bottom: 10px;

    @include media(">=desktop") {
      font-size: rem(50px);
      margin-bottom: 30px;
    }
  }

  strong {
    font-family: var(--heading-font-family);
    letter-spacing: -1.1px;
    line-height: 1.66;

    @include media(">=desktop") {
      font-size: rem(24px);
    }
  }

  p {
    letter-spacing: -0.74px;
    margin-bottom: 10px;

    @include media(">=desktop") {
      font-size: rem(22px);
      letter-spacing: -1.01px;
      margin-bottom: 30px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
