.materials-rtl {
  margin: 68px 0;

  @include media(">=desktop") {
    margin: 127px 0;
  }

  .row {
    margin: 0;
  }

  .col {
    width: 100%;
    padding: 42px;

    @include media(">=desktop") {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 82px;
    }

    &.dark {
      color: var(--materials-rtl-dark-front-color);
      background-color: var(--materials-rtl-dark-back-color);

      @include media('>=desktop') {
        padding: 125px 82px;
      }

      h2 {
        &:first-of-type:not(:only-of-type) {
          margin-bottom: 10px;
        }

        &.h3 {
          letter-spacing: -1.84px;

          @include media(">=desktop") {
            font-size: rem(34px);
            letter-spacing: -1.56px;
          }
        }
      }
    }

    &.light {
      color: var(--materials-rtl-light-front-color);
      background-color: var(--materials-rtl-light-back-color);

      @include media('>=desktop') {
        padding: 159px 138px;
      }

      h2 {
        &.h3 {
          letter-spacing: -1.2px;

          @include media(">=desktop") {
            letter-spacing: -1.38px;
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 25px;

    @include media(">=desktop") {
      margin-bottom: 40px;
    }
  }

  p {
    letter-spacing: -0.74px;
    margin-bottom: 25px;

    @include media(">=desktop") {
      letter-spacing: -1.01px;
      margin-bottom: 40px;
    }
  }

  .files {
    @extend %list-reset;
    margin-bottom: -19px;

    @include media(">=desktop") {
      margin-bottom: -30px;
    }

    li {
      margin-bottom: 19px;

      @include media(">=desktop") {
        margin-bottom: 30px;
      }

      a {
        color: var(--body-color);
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;

        &:hover {
          .file-hold {
            h3 {
              &.h5 {
                text-decoration: none;
              }
            }
          }
        }

        .icon-hold {
          width: 22px;

          i {
            font-size: rem(29px);
          }
        }

        .file-hold {
          width: calc(100% - 22px);
          padding-right: 14px;

          h3 {
            &.h5 {
              letter-spacing: -0.83px;
              text-decoration: underline;
              margin-bottom: 10px;

              @include media(">=desktop") {
                letter-spacing: -0.92px;
              }
            }
          }

          p {
            letter-spacing: -0.83px;
            margin-bottom: 0;

            @include media(">=desktop") {
              font-size: rem(20px);
              letter-spacing: -0.92px;
            }
          }
        }
      }
    }
  }

  .buttons {
    @extend %list-reset;
    margin-bottom: -15px;

    @include media(">=desktop") {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -7.5px -15px;
    }

    li {
      margin-bottom: 15px;

      @include media(">=desktop") {
        padding: 0 7.5px;
      }
    }
  }
}
