.footer {
  text-align: center;
  color: var(--footer-front-color);
  padding: 68px 0;
  background-color: var(--footer-back-color);

  @include media(">=desktop") {
    padding: 127px 0;
  }

  .logo-hold {
    max-width: 204px;
    margin: 0 auto 60px;

    @include media(">=desktop") {
      max-width: 270px;
    }

    img {
      fill: var(--footer-front-color);
    }
  }

  .nav-main,
  .nav-secondary {
    @extend %list-reset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      &.active {
        a {
          font-weight: 700;
          text-decoration: underline;
        }
      }

      a {
        font-family: var(--heading-font-family);
        font-size: rem(18px);
        color: var(--footer-front-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .nav-main {
    margin-bottom: 45px;

    li {
      & + li {
        &:before {
          content: "";
          margin-left: 35px;
        }
      }

      a {
        font-weight: 700;
        letter-spacing: -0.92px;

        @include media(">=desktop") {
          font-size: rem(20px);
        }

        i {
          margin-left: 8px;
        }
      }
    }
  }

  .nav-secondary {
    li {
      & + li {
        &:before {
          content: "|";
          color: var(--footer-nav-secondary-separator-color);
          margin: 0 8px;
        }
      }

      a {
        letter-spacing: -0.83px;
      }
    }
  }
}
